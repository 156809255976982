.header {
  height: 70px;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.5);
  background: #f7f7f7 !important;
  z-index: 20;
}

.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.logo {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 600;
  margin-left: 20px;
  color: #737373;

  &:hover {
    text-decoration: none;
  }
}

.btn_toggle {
  display: none;
}

.menu {
  display: flex;
  margin: 0;
  list-style: none;
  height: 70px;
  align-items: center;
  margin-right: 20px
}

.item {
  text-transform: uppercase;
  height: 100%;
  font-weight: 600;
  border-left: 1px solid #f7f7f7;
  border-right: 1px solid #f7f7f7;

  &.active,
  &:hover {
    .link {
      background-color: #0d52a0;
      color: white;
    }
  }
}

.link {
  color: #555;
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 100%;
  text-decoration: none !important;
  transition: background .2s linear;
  transition: color .2s linear;
}

@media (max-width: 1050px) {

  .header,
  .menu {
    height: 50px;
    font-size: 14px;
  }
}

@media (max-width: 991px) {

  .header,
  .menu {
    height: 40px;
    font-size: 12px;
  }
}

@media (max-width: 888px) {
  .menu {
    font-size: 11px;
  }

  .link {
    padding: 0 7px;
  }
}

@media (min-width: 768px) {
  .menu {
    display: flex !important;
  }
}

@media (max-width: 767px) {
  .btn_toggle {
    display: block;
    font-size: 28px;
    cursor: pointer;
  }

  nav.show .btn_toggle {
    font-size: 20px;
    padding-right: 2px;
  }

  .menu {
    display: none;
  }

  nav.show .menu {
    display: block;
  }


  .header nav {
    margin-right: 20px;
  }

  .wrap {
    max-width: 452px;
    margin: 0 auto;
    position: relative;
  }

  .menu {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    padding: 0;
    margin: 0;
    height: unset;
    flex-direction: column;
    align-items: initial;
  }

  .item {
    height: 40px;
    background-color: #da4242;
    border: none;
  }

  .item.active .link,
  .item:hover .link {
    color: #2d2e36;
    background: none;
  }

  .item.active {
    color: #2d2e36;
  }

  .link {
    color: white;
    font-size: 20px;
    justify-content: center;
  }
}