@import "OpenSans";
@import "Roboto";
@import "Piedra";
@import "~normalize.css/normalize.css";

* {
  &::after,
  &::before{
    box-sizing:border-box
  }
}