/* This stylesheet generated by Transfonter (https://transfonter.org) on October 3, 2017 10:08 PM */

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans/OpenSans-SemiBoldItalic.eot');
  src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'),
  url('../fonts/OpenSans/OpenSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans/OpenSans-SemiBoldItalic.woff') format('woff'),
  url('../fonts/OpenSans/OpenSans-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans/OpenSans-LightItalic.eot');
  src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'),
  url('../fonts/OpenSans/OpenSans-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans/OpenSans-LightItalic.woff') format('woff'),
  url('../fonts/OpenSans/OpenSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans/OpenSans-Bold.eot');
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
  url('../fonts/OpenSans/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans/OpenSans-Bold.woff') format('woff'),
  url('../fonts/OpenSans/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans/OpenSans-ExtraBoldItalic.eot');
  src: local('Open Sans ExtraBold Italic'), local('OpenSans-ExtraBoldItalic'),
  url('../fonts/OpenSans/OpenSans-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans/OpenSans-ExtraBoldItalic.woff') format('woff'),
  url('../fonts/OpenSans/OpenSans-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans/OpenSans-Light.eot');
  src: local('Open Sans Light'), local('OpenSans-Light'),
  url('../fonts/OpenSans/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans/OpenSans-Light.woff') format('woff'),
  url('../fonts/OpenSans/OpenSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans/OpenSans-Italic.eot');
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
  url('../fonts/OpenSans/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans/OpenSans-Italic.woff') format('woff'),
  url('../fonts/OpenSans/OpenSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans/OpenSans-Regular.eot');
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
  url('../fonts/OpenSans/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans/OpenSans-Regular.woff') format('woff'),
  url('../fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans/OpenSans-SemiBold.eot');
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
  url('../fonts/OpenSans/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans/OpenSans-SemiBold.woff') format('woff'),
  url('../fonts/OpenSans/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans/OpenSans-ExtraBold.eot');
  src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
  url('../fonts/OpenSans/OpenSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans/OpenSans-ExtraBold.woff') format('woff'),
  url('../fonts/OpenSans/OpenSans-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans/OpenSans-BoldItalic.eot');
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
  url('../fonts/OpenSans/OpenSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans/OpenSans-BoldItalic.woff') format('woff'),
  url('../fonts/OpenSans/OpenSans-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
